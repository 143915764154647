import "jspdf-autotable";
import * as XLSX from "xlsx";
import React, { useEffect, useMemo, useState } from "react";
import api, { getLoggedInUseremail, getTempuser } from "../../../services/api.js";
import jsPDF from "jspdf";
import { CSVLink } from "react-csv";
import { useTable } from "react-table";

const MyStatement = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchTransactions = async () => {
      const userId = localStorage.getItem('userId');
      const { tempuserEmail, tempuserId } = getTempuser();
      const loggedInEmail = getLoggedInUseremail();
      const emailToUse = tempuserEmail || loggedInEmail;
      const userIdToUse = tempuserId || userId;
      try {
        const transactions = await api.get(`/user-transactions/${userIdToUse}`);
        setData(transactions.data || []); // Ensure data is an array
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    fetchTransactions();
  }, []);

  const columns = useMemo(
    () => [
      { Header: "Transaction ID", accessor: "transaction_id" },
      { Header: "Email", accessor: "email" },
      { Header: "Amount", accessor: "amount" },
      { Header: "Currency", accessor: "currency" },
      { Header: "Reference", accessor: "reference" },
      { Header: "Status", accessor: "status" },
      { Header: "Timestamp", accessor: "timestamp" },
      { Header: "Campaign", accessor: "campaign" },
    ],
    []
  );

  const exportToPDF = () => {
    if (data.length === 0) {
      alert("No data available to export.");
      return;
    }

    const doc = new jsPDF();
    doc.autoTable({
      head: [columns.map(col => col.Header)],
      body: data.map(row => columns.map(col => row[col.accessor] || "")),
    });
    doc.save("statements.pdf");
  };

  const exportToExcel = () => {
    if (data.length === 0) {
      alert("No data available to export.");
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Statements");
    XLSX.writeFile(workbook, "statements.xlsx");
  };

  const tableInstance = useTable({ columns, data });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-xl font-semibold m-4">My Statement (Transactions)</h2>
      <div className="mt-2 mb-2 flex space-x-2 justify-end">
        {data.length > 0 ? (
          <>
            <CSVLink data={data} filename="statements.csv" className="p-2 border rounded bg-blue-500 text-white">
              Export CSV
            </CSVLink>
            <button onClick={exportToPDF} className="p-2 border rounded bg-red-500 text-white">
              Export PDF
            </button>
            <button onClick={exportToExcel} className="p-2 border rounded bg-green-500 text-white">
              Export Excel
            </button>
          </>
        ) : (
          <p>No data available to export.</p>
        )}
      </div>
      {data.length > 0 ? (
        <table {...getTableProps()} className="w-full border-collapse border text-left">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()} className="border p-2 bg-gray-200">
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="hover:bg-gray-100">
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()} className="border p-2">
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <p className="text-center mt-4">No transactions available.</p>
      )}
    </div>
  );
};

export default MyStatement;
