import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation, Link, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

//Campaign API
import { getCampaigns } from '../../services/CampaignService';

//Icons
import { FaBarsStaggered } from "react-icons/fa6";


//Components
import ProgressBar from '../Progress Bar/ProgressBar';
import Navbar from '../NavBar/Navbar';
import Footer from '../Footer/Footer';
import ScrollToTop from '../ScrollToTop/ScrollToTop';

const CategoryFilter = () => {
    const navigate = useNavigate();
    const [campaigns, setCampaigns] = useState([]);
    const [current, setCurrent] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState('All'); // State for selected category
    const intervalRef = useRef(null);
  
    useEffect(() => {
      async function fetchCampaigns() {
        try {
          const data = await getCampaigns();
          const campaignsWithFullImageURLs = data.map(campaign => ({
            ...campaign,
            poster_image_url: `https://backend.iraady.com:8000${campaign.poster_image_url}`
          }));
          setCampaigns(campaignsWithFullImageURLs);
        } catch (error) {
          console.error('Error fetching campaigns:', error);
        }
      }
      fetchCampaigns();
    }, []);

    const updateCurrent = useCallback(() => {
        setCurrent((prevCurrent) =>
          prevCurrent === campaigns.length - 1 ? 0 : prevCurrent + 1
        );
        intervalRef.current = requestAnimationFrame(updateCurrent);
    }, [campaigns.length]);
  
    useEffect(() => {
      function updateCurrent() {
        setCurrent((prevCurrent) =>
          prevCurrent === campaigns.length - 1 ? 0 : prevCurrent + 1
        );
        intervalRef.current = requestAnimationFrame(updateCurrent);
      }
  
      intervalRef.current = requestAnimationFrame(updateCurrent);
  
      return () => cancelAnimationFrame(intervalRef.current);
    }, [campaigns.length]);
  
    const handleDonateClick = () => {
      navigate('/donation-form');
    };
  
    const handleCampaignClick = () => {
      navigate("/campaign");
    };
  
    const handleCategoryChange = (category) => {
      setSelectedCategory(category);
    };
  
    const filteredCampaigns = selectedCategory === 'All'
      ? campaigns
      : campaigns.filter(campaign => campaign.campaign_fundraising_type === selectedCategory);
  
    const { hash } = useLocation();
  
    useEffect(() => {
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [hash]);

    const handleViewInformation = (campaign) => {
      navigate(`/donation-desc/${campaign.campaign_id}`, { state: { campaign } });
    }

    const [isOpen, setIsOpen ] = useState();
    const toggleMenu = () => {
      setIsOpen(!isOpen);
    }
    const closemenu = () => {
      setIsOpen(false);
    } 
  
    return (
        <>
        <Helmet>
          <title>Our Campaigns | Fund Nest</title>
          <meta name="description" content="Our Campaigns" />
        </Helmet>
        <Navbar />
        <div id='learning' className="mt-[60px] md:mt-[90px] bg-[#F5F8FD] bg-opacity-50">
        <div className="my-[30px] justify-center pt-[20px] hidden lg:flex font-semibold font-Main">
          <button
            className={`px-4 py-2 mr-2 ${selectedCategory === 'All' ? 'border-b-2 border-[#4FC0E8]  text-black' : 'border-b-0 transition-all duration-2000'}`}
            onClick={() => handleCategoryChange('All')}
          >
            All
          </button>
          <button
            className={`px-4 py-2 mr-2 ${selectedCategory === 'Health' ? 'border-b-2 border-[#4FC0E8]  text-black' : 'border-b-0 transition-all duration-2000'}`}
            onClick={() => handleCategoryChange('Health')}
          >
            Health
          </button>
          <button
            className={`px-4 py-2 mr-2 ${selectedCategory === 'Education' ? 'border-b-2 border-[#4FC0E8]  text-black' : 'border-b-0 transition-all duration-2000'}`}
            onClick={() => handleCategoryChange('Education')}
          >
            Education
          </button>
          <button
            className={`px-4 py-2 mr-2 ${selectedCategory === 'Religious' ? 'border-b-2 border-[#4FC0E8]  text-black' : 'border-b-0 transition-all duration-2000'}`}
            onClick={() => handleCategoryChange('Religious')}
          >
            Religious
          </button>
          <button
            className={`px-4 py-2 mr-2 ${selectedCategory === 'Floods' ? 'border-b-2 border-[#4FC0E8]  text-black' : 'border-b-0 transition-all duration-2000'}`}
            onClick={() => handleCategoryChange('Floods')}
          >
            Floods
          </button>
          <button
            className={`px-4 py-2 mr-2 ${selectedCategory === 'Wedding' ? 'border-b-2 border-[#4FC0E8]  text-black' : 'border-b-0 transition-all duration-2000'}`}
            onClick={() => handleCategoryChange('Wedding')}
          >
            Wedding
          </button>
          <button
            className={`px-4 py-2 mr-2 ${selectedCategory === 'Funeral' ? 'border-b-2 border-[#4FC0E8]  text-black' : 'border-b-0 transition-all duration-2000'}`}
            onClick={() => handleCategoryChange('Funeral')}
          >
            Funeral
          </button>
          <button
            className={`px-4 py-2 mr-2 ${selectedCategory === 'Civil Society' ? 'border-b-2 border-[#4FC0E8]  text-black' : 'border-b-0 transition-all duration-2000'}`}
            onClick={() => handleCategoryChange('Civil Society')}
          >
            Civil Society
          </button>
          <button
            className={`px-4 py-2 mr-2 ${selectedCategory === 'Business' ? 'border-b-2 border-[#4FC0E8]  text-black' : 'border-b-0 transition-all duration-2000'}`}
            onClick={() => handleCategoryChange('Business')}
          >
            Business
          </button>
          <button
            className={`px-4 py-2 mr-2 ${selectedCategory === 'Other' ? 'border-b-2 border-[#4FC0E8]  text-black' : 'border-b-0 transition-all duration-2000'}`}
            onClick={() => handleCategoryChange('Other')}
          >
            Other
          </button>
        </div>
        <section className=' '>
          <div className='lg:hidden  text-3xl flex flex-col items-end p-4'>
            <FaBarsStaggered onClick={toggleMenu}/>
          </div>
          <div className='relative'>
            <div
              className={`absolute top-0 right-5 w-[45%] bg-white text-black divide-y z-50 text-lg shadow-lg transition-all duration-500 overflow-hidden origin-top-right  ${isOpen ? 'scale-100' : 'scale-0'}`}
            >
              <ul className='divide-y'>
                <li onClick={closemenu}>
                  <button
                    className={`w-full py-1 ${selectedCategory === 'All' ? 'bg-gray-400 border-[#4FC0E8]  text-black' : 'border-b-0 transition-all duration-2000'}`}
                    onClick={() => handleCategoryChange('All')}
                  >
                    All
                  </button>
                </li>
                <li onClick={closemenu}>
                  <button
                    className={`w-full py-1 ${selectedCategory === 'Health' ? 'bg-gray-400 border-[#4FC0E8]  text-black' : 'border-b-0 transition-all duration-2000'}`}
                    onClick={() => handleCategoryChange('Health')}
                    >
                      Health
                  </button>
                </li>
                <li onClick={closemenu}>
                  <button
                    className={`w-full py-1 ${selectedCategory === 'Education' ? 'bg-gray-400 border-[#4FC0E8]  text-black' : 'border-b-0 transition-all duration-2000'}`}
                    onClick={() => handleCategoryChange('Education')}
                  >
                    Education
                  </button>
                </li>
                <li onClick={closemenu}>
                  <button
                    className={`w-full py-1 ${selectedCategory === 'Religious' ? 'bg-gray-400 border-[#4FC0E8]  text-black' : 'border-b-0 transition-all duration-2000'}`}
                    onClick={() => handleCategoryChange('Religious')}
                  >
                    Religious
                  </button>
                </li>
                <li onClick={closemenu}>
                  <button
                    className={`w-full py-1 ${selectedCategory === 'Wedding' ? 'bg-gray-400 border-[#4FC0E8]  text-black' : 'border-b-0 transition-all duration-2000'}`}
                    onClick={() => handleCategoryChange('Wedding')}
                  >
                    Wedding
                  </button>
                </li>
                <li onClick={closemenu}>
                  <button
                  className={`w-full py-1 ${selectedCategory === 'Funeral' ? 'bg-gray-400 border-[#4FC0E8]  text-black' : 'border-b-0 transition-all duration-2000'}`}
                  onClick={() => handleCategoryChange('Funeral')}
                >
                  Funeral
                </button>
                </li>
                <li onClick={closemenu}>
                  <button
                  className={`w-full py-1 ${selectedCategory === 'Civil Society' ? 'bg-gray-400 border-[#4FC0E8]  text-black' : 'border-b-0 transition-all duration-2000'}`}
                  onClick={() => handleCategoryChange('Civil Society')}
                >
                  Civil Society
                </button>
                </li>
                <li onClick={closemenu}>
                  <button
                  className={`w-full py-1 ${selectedCategory === 'Business' ? 'bg-gray-400 border-[#4FC0E8]  text-black' : 'border-b-0 transition-all duration-2000'}`}
                  onClick={() => handleCategoryChange('Business')}
                >
                  Business
                </button>
                </li>
                <li onClick={closemenu}>
                  <button
                  className={`w-full py-1 ${selectedCategory === 'Other' ? 'bg-gray-400 border-[#4FC0E8]  text-black' : 'border-b-0 transition-all duration-2000'}`}
                  onClick={() => handleCategoryChange('Other')}
                >
                  Other
                </button>
                </li>
              </ul>
            </div>
          </div>
        </section>
  
        <div className='w-[90%] relative mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 pb-[35px]'>
          {filteredCampaigns.map((campaign) => (
            <div key={campaign.id} onClick={() => handleViewInformation(campaign)} className='bg-[#F9F7F6] shadow-md transition-all duration-300 ease-linear hover:shadow-lg hover:scale-[103%]'>
              <div className="w-full mx-auto h-[250px]">
                <img src={campaign.poster_image_url} alt={campaign.campaign_name} className='w-full h-full object-cover' />
              </div>
              <div className="text-left p-2">
                <div className='flex justify-between'>
                  <h1 className='text-2xl font-semibold mt-[10px]'>{campaign.campaign_name}</h1>
                  <p className='my-auto text-sm px-2 py-1 bg-[#4FC0E8] rounded-md text-white'>{campaign.campaign_fundraising_type}</p>
                </div>
                <div className='h-[70px] overflow-hidden mt-[10px] text-md font-medium w-full'>
                  <Link to="/donation-desc">
                    <p className='text-ellipsis font-light overflow-hidden'>{campaign.campaign_description}</p>
                  </Link>
                </div>
                <div className='mt-[20px]'>
                  <p className='text-md font-semibold mb-[5px]'>Target: ${campaign.target_amount}</p>
                  <ProgressBar amountRaised={campaign.current_amount} targetAmount={campaign.target_amount} />
                  <p className='text-md font-semibold '>Amount Raised: ${campaign.current_amount}</p>
                  <p className="mt-2 text-gray-700">${campaign.current_amount} raised of ${campaign.target_amount}</p>
                </div>
                <div className="flex flex-col justify-center items-center text-center">
                  <button
                    className="text-[#4FC0E8] text-xl font-medium w-[98%] py-1 border border-[#4FC0E8] rounded-md mt-[20px] hover:bg-[#4FC0E8] hover:text-white transition"
                    onClick={campaign.current_amount >= campaign.target_amount ? null : handleDonateClick}
                    disabled={(campaign.current_amount >= campaign.target_amount) || (campaign.remaining_days <= 0)}
                  >
                    {(campaign.current_amount >= campaign.target_amount) || (campaign.remaining_days <= 0) ? 'Completed' : 'Donate'}
                  </button>  
                </div>            
              </div>
            </div>
          ))}
        </div>
      </div>
      <ScrollToTop />
      <Footer />
      </>
    );
}

export default CategoryFilter;